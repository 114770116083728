import _ from 'lodash';
import axios from 'axios';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

window._ = _;
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: 'MEDIAPRESS_PUSHER',
    wsHost: document.querySelector('meta[name="ws-host"]').getAttribute('content'),
    wsPort: document.querySelector('meta[name="ws-port"]').getAttribute('content'),
    forceTLS: document.querySelector('meta[name="ws-tls"]').getAttribute('content') === 'true',
    disableStats: true,
    // wsHost: document.querySelector('meta[name="pusher-host"]').getAttribute('content'),
    // wssHost: document.querySelector('meta[name="pusher-host"]').getAttribute('content'),
    // wsPort: document.querySelector('meta[name="pusher-port"]').getAttribute('content'),
    // wssPort: document.querySelector('meta[name="pusher-port"]').getAttribute('content'),
    // forceTLS: document.querySelector('meta[name="pusher-tls"]').getAttribute('content') === 'true',
    // enableTransports: ['ws'],
});
