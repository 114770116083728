import './modernizr.js';
import '../css/app.css';
import './bootstrap.js';

import { createApp, h } from 'vue';
import { createInertiaApp } from "@inertiajs/inertia-vue3";
import { InertiaProgress } from "@inertiajs/progress/src";
import * as Sentry from '@sentry/vue';
import {BrowserTracing} from "@sentry/tracing";

InertiaProgress.init();

createInertiaApp({
    resolve: async (name) => {
        const pages = import.meta.glob('../js/Pages/**/*.vue');

        return (await pages[`../js/Pages/${name}.vue`]()).default;
    },
    setup ({ el, app, props, plugin }) {
        const vueApp = createApp({ render: () => h(app, props) })
            .use(plugin);

        const dsn = props.initialPage.props.sentryDsn;

        if (dsn) {
            Sentry.init({
                app: vueApp,
                dsn: dsn,
                ignoreErrors: ['Non-Error promise rejection captured'],
                release: props.initialPage.props.sentyRelease,
            });
        }

        vueApp.mount(el);
    }

})
